<template>
  <div>
    <div v-if="loading">
      <ion-skeleton-text v-for="(_, index) of [0, 1, 2, 3, 4]" :key="index" animated class="my-3 mx-auto small-card" />
    </div>
    <div v-else-if="bookmarkCollections && bookmarkCollections.length">
      <div
        v-for="collection of bookmarkCollections"
        :key="collection.id"
        :bookmarkCollection="collection"
        class="my-3 card-small d-flex"
      >
        <router-link :to="`/bookmarks/details/${collection.slug}`">
          <img
            v-image
            :src="resizeUpload(collection.cropped_img_url || '/empty.png', '80x80')"
            class="clickable-item-hov"
          />
        </router-link>
        <div class="name ml-3 flex-grow-1 info">
          <router-link class="clickable-item-hov py-2 routerlink" :to="`/bookmarks/details/${collection.slug}`">{{
            collection.name
          }}</router-link>
        </div>
      </div>
      <ion-button v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</ion-button>
    </div>
    <div v-else class="no-data">No bookmark lists yet</div>
  </div>
</template>

<script lang="ts" setup>
import { BookmarkCollection, Paging } from '@/shared/types/static-types';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  bookmarkCollections: {
    type: Array as PropType<BookmarkCollection[]>,
    required: true,
  },
  paging: {
    type: Object as PropType<Paging>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['load']);

const nextPageExists = computed(() => {
  return !!props.paging?.next;
});

const requestLoadMore = () => {
  emit('load');
};
</script>

<style scoped lang="sass">
.card-small
  img
    width: 80px
    height: 80px
    background-color: #fff
    object-fit: cover
  .name
    font-size: 20px
    font-weight: bold
  .description
    font-size: 14px
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
      // color: #3dc2ff
  .reaction-count
    font-size: 18px
    font-weight: bold
.no-data
  text-align: left

.clickable-item-hov
  background: none
  border-radius: 10px
.info
  width: calc(100% - 93px)
.routerlink
  color: #214163
.small-card
  height: 94px
  border-radius: 8px
</style>
