<template>
  <div>
    <div v-if="folders && folders.length">
      <Grid :lg="5" :xl="4" :sm="2" :scrollSm="false">
        <FolderCard
          v-for="(folder, index) of folders"
          :key="index"
          class="grid-item"
          :folder="folder"
          :is-selectable="false"
        />
      </Grid>

      <p v-show="hasNextPage" class="clickable" @click="loadMoreCollections">Load More</p>
    </div>
    <div v-else-if="loading">
      <Grid :lg="3" :md="3" :sm="2" :scrollSm="false">
        <ion-skeleton-text v-for="(_, index) of [0, 1, 2, 3, 4]" :key="index" animated class="grid-item small-card" />
      </Grid>
    </div>
    <div v-else class="no-data">
      No folders yet,
      <router-link :to="{ name: 'create-collection' }">Create one!</router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FolderCard from '@/shared/components/storage/FolderCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';

const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  folders: {
    type: Array,
  },
  paging: {
    type: Object,
  },
});

const paging = computed(() => props.paging);
const emits = defineEmits(['load']);

const loadMoreCollections = () => {
  emits('load');
};

const hasNextPage = computed(() => {
  return !!paging.value?.next;
});
</script>

<style scoped lang="sass">
.small-card
  height: 120px
  border-radius: 8px
</style>
