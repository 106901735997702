<template>
  <ion-segment
    scrollable
    mode="ios"
    color="primary"
    class="tabs compact no-shadow bg-transparent"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
      <ion-label class="no-select">{{ tab.name }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array as PropType<Tab[]>,
    required: true,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:activeIndex', 'tab-changed']);

const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = props.tabs[tabIndex];

  emit('update:activeIndex', tabIndex);
  emit('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.tabs
  min-height: 35px !important
  height: 30px !important
  ion-segment-button
    height: 30px !important
    min-height: 30px !important
.shrink
  flex: none !important
</style>
